require('./lib/velocity/velocity.js');
require('./lib/velocity/velocity.ui.js');

$(function() {

    var dollarVal         = $('#pgraph .indicator').data('dollars');
    var $progBar          = $('.pbar');
    var $indicator        = $('.indicator');
    var $indicatedDollars = $('.indicator span.dollars');
    var $ticks            = $('.tick')
    var $red              = $('.pbar.red');
    var maxVal            = 8333
    var targetRatio       = dollarVal / maxVal;

    if (dollarVal <= 333) {
        targetRatio = 0.25 * (dollarVal / 333);
    } else if (dollarVal <= 2000) {
        targetRatio = 0.25 + 0.25 * (dollarVal / 2000);
    } else if (dollarVal <= 5000) {
        targetRatio = 0.5 + 0.25 * (dollarVal / 5000);
    } else if (dollarVal <= 8333) {
        targetRatio = 0.75 + 0.25 * (dollarVal / 8333);
    }

    $indicator.velocity(
        {
            'tween': 1
        },
        {
            duration: 5000,
            delay:    1100,
            progress: function(elements, complete, remaining, start, tweenValue) {
                var meterWidth = $progBar.width();
                var progress   = tweenValue * targetRatio;
                $indicatedDollars.text('$' + Math.floor(dollarVal * tweenValue));

                var percent = progress * 100;

                $indicator.css('margin-left', percent + '%');
                $red.width(percent + '%');

                if (percent >= 25) {
                    //$('.badge').removeClass('ping');
                    $('#t1').addClass('ping');
                    $('#te1').addClass('ping');
                    $('#tee1').addClass('ping');
                }
                if (percent >= 50) {
                    //$('.badge').removeClass('ping');
                    $('#t2').addClass('ping');
                    $('#te2').addClass('ping');
                    $('#tee2').addClass('ping');
                }
                if (percent >= 75) {
                    //$('.badge').removeClass('ping');
                    $('#t3').addClass('ping');
                    $('#te3').addClass('ping');
                    $('#tee3').addClass('ping');
                }
                if (percent >= 100) {
                    //$('.badge').removeClass('ping');
                    $('#t4').addClass('ping');
                    $('#te4').addClass('ping');
                    $('#tee4').addClass('ping');
                    $('.icon-lock').remove();
                }
            }
        }
    );

});